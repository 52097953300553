/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';

import useAuthState from '../global-states/useAuthState';
import CustomHeaderComponent, {
  CustomHeaderComponentProps,
} from '../ui-components/CustomHeaderComponent';
import ExportExcelModal from '../ui-components/order-components/ExportExcelModal';

import { fetchOrders } from '../firebase-logic/orders';
import { fetchUrlLogo } from 'firebase-logic/appearance';

import OrderCard from '../ui-components/order-components/OrderCard';
import DeliveryCard from 'ui-components/order-components/DeliveryCard';
import OrdersHistoryTab from 'ui-components/OrdersHistoryTab';
import PrintingOrderTable from '../ui-components/PrintingOrderTable';
import { useHistory, useLocation } from 'react-router-dom';
import { CateringRequestsTab } from '../ui-components/order-components/Catering/CateringRequestsTab';

export default function OrdersScreen() {
  const auth: any = useAuthState.getState();
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(['orders']);

  const downloadTabletApp = () => {
    window.open(
      'https://apps.apple.com/de/app/droov-orders-management-app/id6444585346?l=en'
    );
  };

  const [activeTab, setActiveTab] = useState(0);
  const [mobileActiveCol, setMobileActiveCol] = useState(0);
  const [openExportSheetModal, setOpenExportSheetModal] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  const [newOrderList, setNewOrderList] = useState([]);
  const [scheduledOrdersOnWait, setScheduledOrdersOnWait] = useState<any[]>([]);
  const [preparingOrderList, setPreparingOrderList] = useState([]);
  const [deliveringOrderList, setDeliveringOrderList] = useState([]);

  const [printingOrder, setPrintingOrder] = useState<any>(null);

  const [urlLogo, setUrlLogo] = useState<any>(null);

  const [showScheduled, setShowScheduled] = useState<boolean>(true);

  const handleHistoryTab = () => {
    const currentPath = history.location.pathname;
    const fragment = 'history';
    history.replace(`${currentPath}#${fragment}`);
    setActiveTab(1);
  };

  const handleNewOrdersTab = () => {
    const currentPath = history.location.pathname;
    const fragment = 'new';
    history.replace(`${currentPath}#${fragment}`);
    setActiveTab(0);
  };

  const handleCateringRequestsTab = () => {
    const currentPath = history.location.pathname;
    const fragment = 'catering';
    history.replace(`${currentPath}#${fragment}`);
    setActiveTab(2);
  };

  useEffect(() => {
    if (printingOrder !== null) {
      fetchUrlLogo(auth.user)
        .then((url) => {
          setUrlLogo(url);

          const content = document.getElementById(
            `order-print-${printingOrder.orderID}`
          );

          const printFrame = document.getElementById('ifmcontentstoprint');
          if (printFrame && content) {
            // @ts-ignore
            const printWindow = printFrame.contentWindow;
            printWindow.document.open();
            printWindow.document.write(content.innerHTML);
            printWindow.document.close();

            const img = printWindow.document.getElementById('logo-img');

            img.addEventListener('load', () => {
              printWindow.focus();
              printWindow.print();

              setPrintingOrder(null);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [auth.user, printingOrder]);

  useEffect(() => {
    const unsubscribe = fetchOrders(auth.user, (snap: any) => {
      const data: any = snap.docs
        .map((doc: any) => doc.data())
        .filter(
          (item: { orderStatus: any; deliveryType: any }) =>
            item?.deliveryType && item?.orderStatus
        );

      console.log('FETCH ORDERS');

      data.sort((item1: any, item2: any) => {
        return item1.orderType - item2.orderType;
      });

      if (data) {
        setNewOrderList(
          data.filter(
            (item: any) =>
              item.orderStatus === 'received' ||
              (item.deliveryType.name === 'scheduled' &&
                item.orderStatus === 'confirmed' &&
                item.deliveryType.scheduledTimeout === true &&
                item.deliveryType.preparationStartTime === null)
          )
        );

        setScheduledOrdersOnWait(
          data.filter(
            (item: any) =>
              item.deliveryType.name === 'scheduled' &&
              item.orderStatus === 'confirmed' &&
              item.deliveryType.scheduledTimeout === false &&
              item.deliveryType.preparationStartTime === null
          )
        );

        setPreparingOrderList(
          data.filter(
            (item: any) =>
              item.orderStatus === 'ready' ||
              (item.orderStatus === 'confirmed' &&
                item.deliveryType.preparationStartTime !== null)
          )
        );

        setDeliveringOrderList(
          data.filter((item: any) => item.orderStatus === 'collectedByCourier')
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth.user]);

  useEffect(() => {
    console.log('counter running');
    const interval = setInterval(() => {
      for (const order of scheduledOrdersOnWait) {
        if (order.deliveryType.scheduledTimeout !== true) {
          markOrderAsScheduledTimeoutIfNeeded(order);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [scheduledOrdersOnWait]);

  useEffect(() => {
    console.log('counter running 2');
    const interval = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (location.hash === '#history') {
      setActiveTab(1);
    } else if (location.hash === '#catering') {
      setActiveTab(2);
    }
  }, [location.hash]);

  const markOrderAsScheduledTimeoutIfNeeded = (order: any) => {
    if (!order.preparationTime) {
      return;
    }

    const preparationTimeInMinutes = order.preparationTime;

    const timeToPrepareInSeconds = preparationTimeInMinutes * 60;
    const estimatedDeliveryTimeInSeconds =
      (order.deliveryType.estimatedDeliveryTime ?? 0.0) * 60;

    let timeWhenPrepared = new Date();
    timeWhenPrepared.setTime(
      timeWhenPrepared.getTime() + timeToPrepareInSeconds * 1000
    );
    timeWhenPrepared.setTime(
      timeWhenPrepared.getTime() + estimatedDeliveryTimeInSeconds * 1000
    );

    // TODO: update that one item with new prop
    // TODO: update deliveryType.scheduledTimeout = true of that order

    if (
      timeWhenPrepared.getTime() >=
      new Date(order.deliveryType.timestamp).getTime()
    ) {
      order.deliveryType.scheduledTimeout = true;
      setScheduledOrdersOnWait((scheduleOnWait: any[]) => {
        let a: any = scheduleOnWait.filter(
          (o: any) => o.documentID !== order.documentID
        );
        a.push(order);
        return a;
      });
    }
  };
  const headerComponent: CustomHeaderComponentProps = {
    heading: activeTab === 2 ? t('catering.label') : t('orders.label'),
    buttonOnClick:
      activeTab === 0
        ? () => downloadTabletApp()
        : () => setOpenExportSheetModal(true),
    buttonString:
      activeTab === 0 ? t('download_app.label') : t('exportorders.label'),
    pulsatingBtn: activeTab === 0 && !auth.pushToken,
    showButton: activeTab !== 2,
  };

  return (
    <div>
      <div className='sticky top-8 bg-droov_background'>
        <CustomHeaderComponent
          heading={headerComponent.heading}
          buttonOnClick={headerComponent.buttonOnClick}
          buttonString={headerComponent.buttonString}
          pulsatingBtn={headerComponent.pulsatingBtn}
          showButton={headerComponent.showButton}
        />
        {/* react-csv(@NPM) */}
        <ExportExcelModal
          openExportPdfModal={openExportSheetModal}
          setOpenExportPdfModal={setOpenExportSheetModal}
        />

        <Tab.Group>
          <Tab.List className='flex justify-start p-1 overscroll-none outline-none'>
            <Tab
              className={`p-2.5 text-sm outline-none overscroll-none leading-5 font-medium transition ease-in-out delay-75 border-b-2 ${
                activeTab === 0
                  ? 'border-droov text-droov'
                  : 'text-gray-400 hover:bg-white/[0.12] hover:text-droov'
              }`}
              onClick={() => handleNewOrdersTab()}
            >
              {t('new_orders.label')} (
              {newOrderList.length +
                preparingOrderList.length +
                deliveringOrderList.length}
              )
            </Tab>
            <Tab
              className={`p-2.5 text-sm outline-none overscroll-none leading-5 font-medium transition ease-in-out delay-75 border-b-2 ${
                activeTab === 1
                  ? 'border-droov text-droov'
                  : 'text-gray-400 hover:bg-white/[0.12] hover:text-droov'
              }`}
              onClick={() => handleHistoryTab()}
            >
              {t('order_history.label')}
            </Tab>
            <Tab
              className={`p-2.5 text-sm outline-none overscroll-none leading-5 font-medium transition ease-in-out delay-75 border-b-2 ${
                activeTab === 2
                  ? 'border-droov text-droov'
                  : 'text-gray-400 hover:bg-white/[0.12] hover:text-droov'
              }`}
              onClick={() => handleCateringRequestsTab()}
            >
              {t('cateringRequests.label')}
            </Tab>
          </Tab.List>
          <Tab.Panels className='outline-none'>
            <Tab.Panel className='rounded-xl p-3 outline-none'>
              {activeTab === 0 && (
                <>
                  <div className='block sm:hidden'>
                    <div className='rounded-xl mb-4 z-10 bg-gray-200 sticky-top'>
                      <div className='flex w-full'>
                        <div className='w-2/6 ml-5 flex justify-end items-center' />
                      </div>
                      <div>
                        <div className='h-14 flex rounded-lg items-center'>
                          <button
                            className={`order_tab_button z-50 w-44 flex-grow ml-1 font-Ubuntu text-sm leading-4 rounded-lg h-12 text-center ${
                              mobileActiveCol === 0 ? 'bg-white' : 'bg-gray-200'
                            }`}
                            onClick={() => setMobileActiveCol(0)}
                          >
                            <p className='flex items-center justify-center mb-0.5'>
                              <div className='w-6 h-6 rounded-full flex justify-center items-center bg-droov text-white'>
                                {newOrderList.length +
                                  scheduledOrdersOnWait.filter(
                                    (order) =>
                                      order.deliveryType.scheduledTimeout ===
                                      true
                                  ).length}
                              </div>
                            </p>
                            {t('new.label')}
                          </button>
                          <button
                            className={`order_tab_button w-48 flex-grow  font-Ubuntu text-sm leading-4 rounded-lg h-12 text-center ${
                              mobileActiveCol === 1 ? 'bg-white' : 'bg-gray-200'
                            }`}
                            onClick={() => setMobileActiveCol(1)}
                          >
                            <p className='flex items-center justify-center mb-0.5'>
                              <div className='w-6 h-6 rounded-full flex justify-center items-center bg-droov text-white'>
                                {preparingOrderList.length}
                              </div>
                            </p>
                            {t('preparing.label')}
                          </button>
                          <button
                            className={`order_tab_button w-44 flex-grow  mr-1 font-Ubuntu text-sm leading-4 rounded-lg h-12 text-center ${
                              mobileActiveCol === 2 ? 'bg-white' : 'bg-gray-200'
                            }`}
                            onClick={() => setMobileActiveCol(2)}
                          >
                            <p className='flex items-center justify-center mb-0.5'>
                              <div className='w-6 h-6 rounded-full flex justify-center items-center bg-droov text-white'>
                                {deliveringOrderList.length}
                              </div>
                            </p>
                            {t('delivering.label')}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col'>
                      {mobileActiveCol === 0 &&
                        newOrderList.map((order: any, index: number) => (
                          <OrderCard
                            key={`order-${index}`}
                            order={{ ...order }}
                            currentTime={currentTime}
                          />
                        ))}
                      {mobileActiveCol === 0 &&
                        scheduledOrdersOnWait.map(
                          (order: any, index: number) =>
                            order.deliveryType.scheduledTimeout === true && (
                              <OrderCard
                                key={`order-${index}`}
                                order={{ ...order }}
                                currentTime={currentTime}
                                print={setPrintingOrder}
                              />
                            )
                        )}
                      {mobileActiveCol === 0 &&
                        scheduledOrdersOnWait.filter(
                          (so) => so.deliveryType.scheduledTimeout === false
                        ).length > 0 && (
                          <button
                            className='order_yellow_button mx-auto fixed'
                            style={{
                              left: '50%',
                              transform: 'translateX(-50%)',
                              bottom: '2%',
                            }}
                            onClick={() => {
                              setShowScheduled((currentValue) => {
                                return !currentValue;
                              });
                            }}
                          >
                            {showScheduled
                              ? t('show_scheduled_orders.label')
                              : t('hide_scheduled_orders.label')}
                          </button>
                        )}

                      {mobileActiveCol === 0 &&
                        scheduledOrdersOnWait.map(
                          (order: any, index: number) =>
                            order.deliveryType.scheduledTimeout ===
                              showScheduled &&
                            !showScheduled && (
                              <OrderCard
                                key={`order-${index}`}
                                order={{ ...order }}
                                currentTime={currentTime}
                              />
                            )
                        )}

                      {mobileActiveCol === 1 &&
                        preparingOrderList.map((order: any, index: number) => (
                          <OrderCard
                            key={`order-${index}`}
                            order={{ ...order }}
                            currentTime={currentTime}
                          />
                        ))}

                      {mobileActiveCol === 2 &&
                        deliveringOrderList.map((order: any, index: number) => (
                          <DeliveryCard
                            key={`order-${index}`}
                            order={{ ...order }}
                            delivering={t('delivering.label')}
                            delivered={t('delivered.label')}
                          />
                        ))}
                    </div>
                  </div>

                  <div className='hidden sm:block'>
                    <div className='flex'>
                      <div className='w-5/12 pr-2'>
                        <div className='mb-2 text-center font-bold justify-center'>
                          {t('new.label')} (
                          {newOrderList.length +
                            scheduledOrdersOnWait.filter(
                              (order) =>
                                order.deliveryType.scheduledTimeout === true
                            ).length}
                          )
                        </div>
                        {newOrderList.map((order: any, index: number) => (
                          <OrderCard
                            key={`order-${index}`}
                            order={{ ...order }}
                            currentTime={currentTime}
                            print={setPrintingOrder}
                          />
                        ))}
                        {scheduledOrdersOnWait.map(
                          (order: any, index: number) =>
                            order.deliveryType.scheduledTimeout === true && (
                              <OrderCard
                                key={`order-${index}`}
                                order={{ ...order }}
                                currentTime={currentTime}
                                print={setPrintingOrder}
                              />
                            )
                        )}

                        {scheduledOrdersOnWait.filter(
                          (so) => so.deliveryType.scheduledTimeout === false
                        ).length > 0 && (
                          <button
                            className='mx-auto flex justify-center items-center bg-yellow-200 text-black rounded-lg px-5 mt-4 mb-4 h-10 hover:bg-yellow-400 duration-200 transition-colors ease-in-out text-sm'
                            onClick={() => {
                              setShowScheduled((currentValue) => {
                                return !currentValue;
                              });
                            }}
                          >
                            {showScheduled
                              ? t('show_scheduled_orders.label')
                              : t('hide_scheduled_orders.label')}
                          </button>
                        )}

                        {scheduledOrdersOnWait.map(
                          (order: any, index: number) =>
                            order.deliveryType.scheduledTimeout ===
                              showScheduled &&
                            !showScheduled && (
                              <OrderCard
                                key={`order-${index}`}
                                order={{ ...order }}
                                currentTime={currentTime}
                                print={setPrintingOrder}
                              />
                            )
                        )}
                      </div>
                      <div className='ml-2 w-5/12 pr-2'>
                        <div className='mb-2 text-center font-bold'>
                          {t('preparing.label')} ({preparingOrderList.length})
                        </div>
                        {preparingOrderList.map((order: any, index: number) => (
                          <OrderCard
                            key={`order-${index}`}
                            order={{ ...order }}
                            currentTime={currentTime}
                            print={setPrintingOrder}
                          />
                        ))}
                      </div>
                      <div className='ml-2 w-2/12 pr-2'>
                        <div className='mb-2 text-center font-bold'>
                          {t('delivering.label')} ({deliveringOrderList.length})
                        </div>
                        {deliveringOrderList.map(
                          (order: any, index: number) => (
                            <DeliveryCard
                              key={`order-${index}`}
                              order={{ ...order }}
                              delivering={t('delivering.label')}
                              delivered={t('delivered.label')}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Tab.Panel>
            <Tab.Panel className='rounded-xl p-3 outline-none' />
          </Tab.Panels>
        </Tab.Group>

        {printingOrder !== null && (
          <iframe
            id='ifmcontentstoprint'
            style={{ height: '0px', width: '0px', position: 'absolute' }}
            title='print'
          />
        )}

        {printingOrder !== null && (
          <div id={`order-print-${printingOrder.orderID}`}>
            <PrintingOrderTable
              urlLogo={urlLogo}
              printingOrder={printingOrder}
            />
          </div>
        )}

        {activeTab === 1 && <OrdersHistoryTab />}

        {activeTab === 2 && <CateringRequestsTab />}
      </div>
    </div>
  );
}
