export type ValueOf<T> = T[keyof T];

export interface DiscountObject {
  value: number;
  code: string;
  type: DiscountType;
  date: Date | null | undefined;
  usage: number;
  color?: string;
  minOrderValue?: number;
  maxUsage?: number;
  hasNoExpirationDate?: boolean;
  id?: string;
}

export interface CompanyData {
  delivery: boolean;
  name: string;
  pickup: boolean;
  urlCoverImage: string;
  urlLogo: string;
}

export interface Product {
  name: string;
  imgURL: string;
  id?: string;
  category: string;
  order?: number;
  price: number;
  nettoPrice?: number;
  description: string;
  addons?: any;
  labels?: any;
  sizes?: any;
  defaultSize?: any;
  vat?: any;
  vatAmount?: any;
  visible: boolean;
}

export interface Sizes {
  size: string;
  price: number;
  default: boolean;
}

export interface Category {
  name: string;
  imgURL: string;
  visible: boolean;
  id?: string;
  products?: Array<string>;
  order?: number;
  emoji?: string;
}

export interface DiscountProps {
  discountObject: DiscountObject;
  onClicked: () => void;
}

export enum DiscountType {
  Currency = 'Euro',
  Percent = 'Percent',
}
// Couriers
export interface CourierObject {
  photo?: string;
  courierName: string;
  phoneNumber: string;
  email?: string;
  vehicleType: CourierVehicleType;
  ecoFriendly?: boolean;
  id?: string;
  profileURL?: string;
  invited: boolean;
  status: string;
}

export interface CourierProps {
  courierObject: CourierObject;
  onClicked: () => void;
}

export enum CourierVehicleType {
  Bike = 'Bike',
  Car = 'Car',
  Walking = 'Walking',
}

//Orders
export interface OrderObject {
  orderId?: string;
  customer?: {
    uid: string;
    name: string;
    phone: number;
  };
  deliveryType?: {
    name: string;
    timestamp: number;
  };
  deliveryFee?: number;
  amount?: number;
  nettAmount?: number;
  tip?: number;
  vat?: number;
  vatBreakdown?: any[];
  comments?: string;
  documentID?: string;
  orderItems?: {
    [key: string]: {
      itemId: string;
      itemName: string;
      itemPrice: string;
      itemQuantity: number;
      itemSpecs: {
        // wrong type
        Base: string;
        Zubereitung: string;
      };
      itemVat: number;
      itemSize: string;
      itemImageURL: string;
    };
  };
  orderStatus?: string;
  orderTimestamp?: {
    [key: string]: number;
  };
  orderType?: string;
  paymentType?: string;
  preparationTime?: number;
  discount?: {
    type?: string;
    amount?: number;
    value?: number;
  };
}

export interface OrderProps {
  orderObject: OrderObject;
  onClicked: (orderId: string) => void;
  onDeleted: (orderId: string) => void;
}

export enum PaymentType {
  PayPal = 'PayPal',
  Card = 'Card',
  Apple = 'Apple',
  Cash = 'Cash'
}
//Firebase Order Object
export interface FirebaseOrderObject {
  customer: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: number | string;
  };
  payment: {
    amount: number;
    deliveryFee: number;
    paymentMethod: {
      type: string;
      paymentRef: string;
    };
  };
  orderCreated: any;
  id?: string;
}

export interface FirebaseOrderProps {
  firebaseOrderObject: FirebaseOrderObject;
  onClicked: (firebaseOrder: FirebaseOrderObject) => void;
}

export interface DeliveryDataObject {
  id: string;
  name: string;
  deliveryFee: number;
  minOrder: number;
  color: string;
  path: Location[];
}

export interface Location {
  lat: number;
  lng: number;
}

export enum FeedbackTag {
  GoodJob = 'Good Job! 😊',
  Criticise = 'Criticise 😕',
  Idea = 'Idea 💡',
  Bug = 'Bug 🐞',
}
export interface FeedbackObject {
  tag?: FeedbackTag;
  message: string;
}

export enum FoodLabels {
  Vegan = 'vegan.label',
  Vegetarian = 'vegetarian.label',
  GlutenFree = 'glutenfree.label',
  Organic = 'organic.label',
  SugarFree = 'sugarfree.label',
  LactoseFree = 'lactosefree.label',
  NutsFree = 'nutsfree.label',
  Halal = 'halal.label',
  Kosher = 'kosher.label',
  AlcoholFree = 'alcoholfree.label',
  Light = 'light.label',
  Spicy = 'spicy.label',
  Hot = 'hot.label',
}

export type Subscription = {
  current_period_start: Date;
  current_period_end: Date;
  cancel_at_period_end: boolean;
  canceled_at?: Date;
  status: string;
  trial_end?: Date;
  trial_start?: Date;
  metadata: { customerId: string };
};

export type Customer = {
  id: string;
  name: string;
  email: string;
  address: {
    city: string;
    line1: string;
    line2: string;
    country: string;
    postal_code: string;
  };
  phone: string;
  vat: string;
  customer_name: string;
};

export type PaymentMethod = {
  number: string;
  cvc: string;
  exp_month: number;
  exp_year: number;
};

export type Invoice = {
  id: string;
  date: Date;
  status: string;
  amount: number;
  url: string;
};

export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incompleteExpired = 'incomplete_expired',
  pastDue = 'past_due',
  trialing = 'trialing',
  unpaid = 'unpaid',
  default = 'default',
}
