import { ReactComponent as EditSVG } from '../assets/editIcon.svg';
import { useTranslation } from 'react-i18next';

export default function EditIcon() {
  const { t } = useTranslation(['common']);
  return (
    <div className='group flex cursor-pointer text-gray-400 hover:text-gray-800 duration duration-300'>
      <EditSVG className='flex cursor-pointer fill-current text-gray-400 hover:text-gray-800 duration duration-300' />
      <span className='tooltip top-5 right-0 absolute py-1 px-2 bg-black text-white text-xs rounded whitespace-nowrap opacity-0 group-hover:opacity-100'>
        {t('edit.button')}
      </span>
    </div>
  );
}
