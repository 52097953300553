import { useEffect, useState } from 'react';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FoodLabels } from '../../../types';
import { enumKeys } from 'utils/enumFunctions';
import { useTranslation } from 'react-i18next';
import ModalPopup from './ModalPopup';

export default function AddLabelsModal({
  addLabels,
  setOpenAddLabelsModal,
  product,
  openAddLabelsModal,
}: any) {
  const { t } = useTranslation(['catalogue']);

  const [labelsForm, setLabelsForm]: any = useState(() => {
    const tempLabelForm = JSON.parse(
      JSON.stringify(
        (product?.labels !== undefined && product.labels) || //if we open modal from product
          [] //if we create new label from product
      )
    );
    return tempLabelForm;
  });

  const handleSave = () => {
    handleSaveForProduct();
    setOpenAddLabelsModal(false);
  };

  const handleSaveForProduct = () => {
    addLabels(labelsForm, product.id);
    return;
  };

  const cancel = () => {
    setLabelsForm(labelsForm);
    setOpenAddLabelsModal(false);
  };

  const modal = (e: any) => {
    e.preventDefault();
    handleSave();
  };

  const handleClick = (labelType: string) => {
    let isChecked = false;
    labelsForm.forEach((label: string) => {
      if (label === labelType) {
        isChecked = true;
      }
    });

    setLabelsForm(
      isChecked
        ? labelsForm.filter((label: string) => label !== labelType)
        : [...labelsForm, labelType]
    );
  };

  const foodLabelKeys = enumKeys(FoodLabels);
  return (
    <ModalPopup
      modal={modal}
      cancel={cancel}
      openAddEntityModal={openAddLabelsModal}
      modalType={'labels'}
    >
      <div className='flex flex-wrap gap-4 auto-rows-max mb-4'>
        {foodLabelKeys.map((label) => {
          return (
            <div
              onClick={() => {
                handleClick(label);
              }}
              key={label}
              className='flex items-center justify-between transition duration-200 rounded-3xl shadow col-span-1 pl-1.5 pr-2 py-1.5 cursor-pointer hover:shadow-md'
            >
              <div className='text-sm ml-2 flex items-center'>
                <span>{t(FoodLabels[label])}</span>
              </div>
              {labelsForm.includes(label) ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='text-droov top-2 right-2 ml-3 mr-0.5 w-6 h-6 tick'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                  stroke='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='none'
                  stroke='currentColor'
                  className='text-droov top-2 right-2 ml-3 mr-0.5 w-6 h-6 tick'
                >
                  <path
                    fillRule='evenodd'
                    d='M 13 8 m 6 2 a 9 9 0 1 1 -18 0 a 9 9 0 0 1 18 0 z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
            </div>
          );
        })}
      </div>
    </ModalPopup>
  );
}
