import { Location, SubscriptionStatus } from '../types';
import db from './firebase';
import { DaysDateIsoRange } from '../typings/holiday';
import firebase from 'firebase';

export interface SettingsContactObject {
  contactEmail: string;
  storeName: string;
  telephone: string;
  storeDescription: string;
}

export interface SettingsLocationObject {
  streetAndHouseNumber: string;
  additionalInformation: string;
  postCode: string;
  city: string;
  country: string;
  geoCode: Location;
}

export interface OperatingHours {
  from: string;
  to: string;
}

export interface WorkingDay {
  operating: boolean;
  operatingHours: Array<OperatingHours>;
}

export enum Days {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export type WorkingHoursProp = Record<Days, WorkingDay>;

enum WorkingEntities {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
}
export type WorkingHoursComplete = Record<WorkingEntities, WorkingHoursProp>;

export interface userDetails {
  companyName: string;
  companyLanguage: string;
  customerName: string;
  countryCode: string;
  urlStore: string;
  companyCurrency: string;
  clockFormat12: boolean;
  pushToken?: string | null;
  showNetto: boolean;
  timezone: string;
}

export interface customWorkingHoursScreenProps {
  operatingTimes: WorkingHoursProp;
  submitFunction: any;
  setOperatingTimes: (data: WorkingHoursProp) => void;
  loading: boolean;
  headingForTheTable: string;
  subHeadingForTheTable: string;
  copyFromOtherTabOnClick: any;
  setIsValid: (data: boolean) => void;
}

export interface IcompanyStoreSettings {
  defaultTip: number;
  defaultTipping: boolean;
  groupOrder: boolean;
  groupOrderDiscount: number;
  groupOrderMinValue: number;
  customerNotifications: boolean;
  basketLimit: {
    enabled: boolean;
    basketLimit: number;
  };
  catering: {
    enabled: boolean;
  };
  timeslotLimit: {
    avgItemPrice: number;
    enabled: boolean;
    maxItems: number;
  };
}

export interface deliveryAndPickupStatus {
  delivery: boolean;
  pickup: boolean;
}

export const getStoreSettings = async (user: string) => {
  // get the store settings from the database
  const companyDetails = await db.collection('companies').doc(user).get();
  const storeSettings: IcompanyStoreSettings =
    companyDetails?.get('storeSettings');

  if (storeSettings) return storeSettings;
  else {
    console.log('No user details found');
    console.log("Data is null/ doesn't exist for user " + user);
  }
  return null;
};

export const createOrUpdateStoreSettings = async (
  user: string,
  storeSettings: IcompanyStoreSettings
) => {
  try {
    await db
      .collection('companies')
      .doc(user)
      .update({ storeSettings: storeSettings });
  } catch (e) {
    console.error('Error in creating store settings', e);
    return e;
  }
};

export const getDocFromThePath = async (user: any, pathForSettings: string) => {
  try {
    const docFromPath = await db
      .collection('companies')
      .doc(user)
      .collection('settings')
      .doc(pathForSettings)
      .get();
    if (docFromPath.exists) {
      const localObject = {
        ...docFromPath.data(),
      };
      return localObject;
    } else {
      console.log('path doesnot exist  user: ' + user + ' ' + docFromPath);
      throw new Error("Data is null/ doesn't exist for user " + user);
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createSettingForContactDetails = async (
  user: any,
  settingsContactObject: SettingsContactObject
) => {
  try {
    await db
      .collection('companies')
      .doc(user)
      .update({ contactSetting: settingsContactObject });
    return;
  } catch (e) {
    return e;
  }
};

export const createSettingForLocationDetails = async (
  user: any,
  settingsLocationObject: SettingsLocationObject
) => {
  try {
    console.log('settingsObj', settingsLocationObject);
    await db
      .collection('companies')
      .doc(user)
      .update({ locationSetting: settingsLocationObject });
  } catch (e) {
    console.error('Error in creating Location details', e);
    return e;
  }
};

export const createSettingForDeliveryHours = async (
  user: any,
  workingHoursComplete: WorkingHoursComplete,
  holidays: DaysDateIsoRange[]
) => {
  try {
    const companiesCollection = db
      .collection('companies')
      .doc(user)
      .collection('settings')
      .doc('operatingTimes');

    // TODO: legacy operation, remove upon migration
    if (!(await companiesCollection.get()).exists) {
      // delete the previous place of storing operating time and set new place
      await db
        .collection('companies')
        .doc(user)
        .update({ operatingTimes: firebase.firestore.FieldValue.delete() });
      await companiesCollection.set({ ...workingHoursComplete, holidays });
    } else {
      await companiesCollection.update({ ...workingHoursComplete, holidays });
    }
  } catch (e) {
    console.error('Error in creating Operating times', e);
    return e;
  }
};

export const getHolidayRanges = async (
  user: string
): Promise<DaysDateIsoRange[]> => {
  try {
    const companyDetails = await db
      .collection('companies')
      .doc(user)
      .collection('settings')
      .doc('operatingTimes')
      .get();

    // TODO: legacy operation, remove upon migration
    if (!companyDetails.exists) {
      const companyDetails = await db.collection('companies').doc(user).get();
      return companyDetails.get('operatingTimes').holidays;
    }

    return companyDetails.data()?.holidays ?? [];
  } catch (e) {
    console.error('Error in creating holiday ranges', e);
    throw new Error((e as string).toString());
  }
};

export const getDeliveryStatus = async (user: any) => {
  const val = await await db.collection('companies').doc(user).get();
  const deliveryAndPickupStatus: deliveryAndPickupStatus = {
    delivery: val.get('delivery'),
    pickup: val.get('pickup'),
  };
  return deliveryAndPickupStatus;
};

export const getUserDetails = async (user: any) => {
  const val = await db.collection('companies').doc(user).get();
  const companyName: string = val.get('name');
  const urlStore: string = val.get('urlStore');
  const localizationSetting = val.get('localizationSetting');
  const billingSetting: any = val.get('billingSetting');
  const notificationSetting: any = val.get('notificationSetting');
  const UserDetails: userDetails = {
    companyName: companyName,
    companyLanguage: localizationSetting
      ? localizationSetting.language.toLowerCase()
      : '',
    urlStore: urlStore,
    customerName: billingSetting ? billingSetting.customer_name : '',
    countryCode: localizationSetting ? localizationSetting.countryCode : '',
    companyCurrency: localizationSetting ? localizationSetting.currency : '',
    clockFormat12: localizationSetting
      ? localizationSetting.clockFormat12
      : null,
    pushToken: notificationSetting ? notificationSetting.pushToken : null,
    showNetto: localizationSetting ? localizationSetting.showNetto : false,
    timezone: localizationSetting ? localizationSetting.timeZoneID : '',
  };
  return UserDetails;
};

export const getCompanyLocation = async (user: any) => {
  try {
    const companyDetails = await db.collection('companies').doc(user).get();
    const companyLocation = companyDetails?.get('locationSetting');
    if (companyLocation) {
      return companyLocation;
    } else {
      console.log('No user details found');
      throw new Error("Data is null/ doesn't exist for user " + user);
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getCompanyDetails = async (user: any) => {
  try {
    const companyData = await db.collection('companies').doc(user).get();
    const companyDetails = companyData?.get('contactSetting');
    if (companyDetails) {
      return companyDetails;
    } else {
      console.log('No company details found');
      throw new Error("Data is null/ doesn't exist for user " + user);
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getCompanyWorkingHours = async (user: any) => {
  try {
    const [companyDetails] = await Promise.all([
      db
        .collection('companies')
        .doc(user)
        .collection('settings')
        .doc('operatingTimes')
        .get(),
    ]);

    // TODO: legacy operation, remove upon migration
    if (!companyDetails.exists) {
      const companyDetails = await db.collection('companies').doc(user).get();
      return {
        delivery: companyDetails.get('operatingTimes').delivery,
        pickup: companyDetails.get('operatingTimes').pickup,
      };
    }

    const companyWorkingHours = companyDetails.data();
    if (companyWorkingHours) {
      return {
        delivery: companyWorkingHours.delivery,
        pickup: companyWorkingHours.pickup,
      };
    } else {
      console.log('No user details found');
      throw new Error("Data is null/ doesn't exist for user " + user);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSubscriptionStatus = async (user: any) => {
  try {
    const subscriptionStatus = await db
      .collection('subscriptions')
      .doc(user)
      .get();
    console.log(subscriptionStatus);
    return SubscriptionStatus[
      subscriptionStatus.get('status') as keyof typeof SubscriptionStatus
    ];
  } catch (error) {
    console.error(error);
  }
};
