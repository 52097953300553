import { useEffect, useState } from 'react';
import CustomInputField from '../../CustomInputField';
import { ReactComponent as LinkIcon } from '../../../assets/link.svg';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import NumberInput from '../../../ui-components/NumberInput';
import { modalConfirm } from '../../ConfirmationModal';
import OptionalOrMandatory from '../../OptionalOrMandatory';
import AddonGroupElement from './AddonGroupElement';
import { WithImagesSwitch } from './WithImagesSwitch';
import { deleteFileByPath } from 'firebase-logic/couriers';
import useAuthState from 'global-states/useAuthState';
import { AllowMultipleSwitch } from './AllowMultipleSwitch';
import { useTranslation } from 'react-i18next';

export default function AddAddonsModal({
  deleteAddons,
  setOpenAddAddonsModal,
  configuratorState,
  product,
  openAddAddonsModal,
  addonsImages,
  addonToEdit,
  setAddonToEdit,
  addAddons,
}: any) {
  const auth: any = useAuthState.getState();
  const [openImageGalery, setOpenImageGalery] = useState(-1); //-1 when it is closed
  const [filteredImages, setFilteredImages]: any = useState([]);
  const [error, setError] = useState('');
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const [imagesForDelete, setImagesForDelete] = useState<any[]>([]);
  // console.log(product)
  const [addonsForm, setAddonsForm]: any = useState({
    //if we open modal from configurator
    title: '',
    maximum: 1,
    minimum: 0,
    mandatory: false,
    withImages: false,
    allowMultiple: false,
    items: [
      {
        addon: '',
        price: '',
        ...(product !== undefined && { nettoPrice: '' }),
        imgURL: '',
      },
    ],
  });
  useEffect(() => {
    const updatedAddOnForm = JSON.parse(
      JSON.stringify(
        (addonToEdit !== -1 &&
          product?.addons !== undefined &&
          product?.addons[addonToEdit]) || //if we open modal from product
          (addonToEdit !== -1 &&
            Object.values(configuratorState?.addons[addonToEdit])[0]) || {
            //if we open modal from configurator
            title: '',
            maximum: 1,
            minimum: 0,
            mandatory: false,
            withImages: false,
            allowMultiple: false,
            items: [
              {
                addon: '',
                price: '',
                ...(product !== undefined && { nettoPrice: '' }),
                imgURL: '',
              },
            ],
          } //if we create new addon from product
      )
    );
    setAddonsForm(updatedAddOnForm);
    setMandatory(updatedAddOnForm.mandatory);
  }, [addonToEdit, configuratorState?.addons, product]);

  const [mandatory, setMandatory] = useState(addonsForm?.mandatory || false);
  const [withImages, setWithImages] = useState(addonsForm?.withImages || false);
  const [allowMultiple, setAllowMultiple] = useState(
    addonsForm?.allowMultiple || false
  );
  const { t } = useTranslation(['catalogue']);

  const handleSave = () => {
    if (addonsForm.minimum > addonsForm.maximum) {
      setError('The minimum should be lower than maximum');
      return;
    }
    if (product?.id === undefined) {
      handleSaveForConfigurator();
    } else {
      handleSaveForProduct();
    }
    deleteImages(false);
    setOpenAddAddonsModal(false);
  };
  // console.log(addonsForm);
  // console.log(product.id);
  // console.log( product.vat);
  // console.log(Object.keys(configuratorState?.addons[addonToEdit])[0]);
  const handleSaveForConfigurator = () => {
    if (addonToEdit === -1) {
      addAddons({ ...addonsForm, general: true });
    } else {
      addAddons(
        { ...addonsForm, general: true },
        undefined,
        undefined,
        Object.keys(configuratorState?.addons[addonToEdit])[0],
        addonToEdit,
        undefined,
        undefined
      );
      setAddonToEdit(-1);
    }
    return;
  };

  const handleSaveForProduct = () => {
    if (addonToEdit === -1) {
      //create
      addAddons(
        { ...addonsForm, general: false },
        product?.id,
        product.vat,
        undefined,
        undefined,
        undefined,
        openAddAddonsModal ? true : false
      );
    } else {
      //update
      addAddons(
        { ...addonsForm, general: true },
        product.id,
        product.vat,
        Object.keys(product?.addons[addonToEdit])[0],
        addonToEdit
      );
      setAddonToEdit(-1);
    }
    return;
  };

  const handleAddAddons = () => {
    //when we add a new addon to the group
    setAddonsForm({
      ...addonsForm,
      items: [
        ...addonsForm.items,
        {
          addon: '',
          price: '',
          ...(product !== undefined && { nettoPrice: '' }),
          imgURL: '',
        },
      ],
    });
  };
  const toggleOpenImageGalery = (i: number) => {
    openImageGalery === -1 ? setOpenImageGalery(i) : setOpenImageGalery(-1);
  };

  const handleInputChange = (name: any, value: any, index: number) => {
    let temp_state = [...addonsForm.items];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setAddonsForm({ ...addonsForm, items: temp_state });
  };

  const handleRemoveClick = (index: number) => {
    const list = [...addonsForm.items];
    if (list[index]) {
      setImagesForDelete([
        ...imagesForDelete,
        list[index].imgId ? list[index].imgId : '',
      ]);
    }
    if (list.length > 1) {
      //if it not the first addon
      list.splice(index, 1);
      setAddonsForm({ ...addonsForm, items: list });
    }
  };

  const handleDelete = async () => {
    if (product === undefined) {
      //at configurator
      deleteAddons(
        Object.keys(configuratorState?.addons[addonToEdit])[0],
        undefined,
        addonToEdit
      );
    } else {
      //at product
      deleteAddons(
        Object.keys(product?.addons[addonToEdit])[0],
        product.id,
        addonToEdit
      );
    }
    setOpenAddAddonsModal(false);
    setAddonToEdit(-1);
  };

  const showImages = async (input: string, i: number) => {
    if (input.length === 1 || input.length === 0) {
      //not suggeest images until two caracters have been typed
      addonsForm.items[i].imgURL = '';
      return;
    }
    input = input.toLowerCase();
    addonsImages.forEach((item: any) => {
      //iterate through all images to find the best matching
      item.name.forEach((subItem: any) => {
        if (subItem.includes(input)) {
          filteredImages.push(item.imgURL);
          subItem = subItem.toLowerCase();
          addonsForm.items[i].imgURL = item.imgURL;
        }
      });
    });
    // const searchedImages = await searchAddonImages(input)
    // console.log(searchedImages)
  };

  const cancel = () => {
    deleteImages(true);
    setAddonsForm(addonsForm);
    setAddonToEdit(-1);
    setOpenAddAddonsModal(false);
  };

  const deleteImages = async (fromCancel: boolean) => {
    if (fromCancel) {
      for (let index = 0; index < uploadedImages.length; index++) {
        const element = uploadedImages[index];
        await deleteFileByPath(auth.user, 'addons', element);
      }
    } else {
      for (let index = 0; index < imagesForDelete.length; index++) {
        const element = imagesForDelete[index];
        await deleteFileByPath(auth.user, 'addons', element);
      }
    }
  };
  const modal = (e: any) => {
    e.preventDefault();
    product === undefined
      ? addonToEdit === -1
        ? handleSave()
        : modalConfirm(
            {
              message: t('updateLinkedAddons.label'),
              title: t('update.label'),
            },
            handleSave
          )
      : addonsForm?.refId === undefined
      ? handleSave()
      : modalConfirm(
          {
            message: t('updateUnlinkAddons.label'),
            title: t('update.label'),
          },
          handleSave
        );
  };

  const cancelButtonRef: any = useRef();

  return (
    <Transition.Root show={openAddAddonsModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className=' fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={!!openAddAddonsModal}
        onClose={cancel}
      >
        <div className=' flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className=' fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <form
              onSubmit={(e) => modal(e)}
              className='inline-block align-bottom bg-white rounded-xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle p-6 '
            >
              <h1 className='text-lg flex items-center'>
                {addonToEdit === -1 ? (
                  <b>{t('createaddongroup.label')}</b>
                ) : (
                  <b>{t('editaddongroup.label')}</b>
                )}

                <div className='group flex'>
                  {addonsForm?.refId !== undefined && (
                    <>
                      <LinkIcon className='h-4 mt-0.5 ml-1' />
                      <span className='hidden sm:inline-block  text-sm bg-yellow-200 px-2 rounded-md opacity-0 group-hover:opacity-100 duration-300'>
                        Item linked to configurator
                      </span>
                    </>
                  )}
                </div>
              </h1>

              <p className=' text-gray-400 mt-2 mb-4'>
                {t('createaddongroup.text')}
              </p>

              <>
                <b className='text-md'>{t('groupname.label')}</b>
                <div className='flex mt-2 justify-between flex-wrap'>
                  <div className='w-full sm:w-1/2'>
                    <CustomInputField
                      classnameadditional='h-9 placeholder-italic mr-5'
                      name='categoryName'
                      type='text'
                      placeholder='Title (e.g Proteins or Base)'
                      value={addonsForm.title}
                      id='categoryName'
                      maxLength={40}
                      onChange={(e) => {
                        setAddonsForm({
                          ...addonsForm,
                          title: e.target.value,
                        });
                      }}
                      required={true}
                    />
                  </div>

                  <div className='w-full sm:w-1/2 mt-2 sm:mt-1 flex items-center justify-end sm:justify-end'>
                    <OptionalOrMandatory
                      addonsForm={addonsForm}
                      setAddonsForm={setAddonsForm}
                      mandatory={mandatory}
                      setMandatory={setMandatory}
                    />
                  </div>
                </div>
              </>

              <div className='flex justify-between mt-3'>
                <div className='flex'>
                  {mandatory && (
                    <div className='mr-2'>
                      <b className='text-md'>{t('minimum.label')}</b>
                      <NumberInput
                        value={addonsForm.minimum}
                        onNumberChanged={(newNumberValue: number) => {
                          setAddonsForm({
                            ...addonsForm,
                            minimum: newNumberValue,
                          });
                        }}
                      />
                    </div>
                  )}

                  <div className=''>
                    <b className='text-md'>{t('maximum.label')}</b>

                    <NumberInput
                      value={addonsForm.maximum}
                      onNumberChanged={(newNumberValue: number) => {
                        setAddonsForm({
                          ...addonsForm,
                          maximum: newNumberValue,
                        });
                      }}
                    />
                  </div>
                </div>

                <WithImagesSwitch
                  addonsForm={addonsForm}
                  setAddonsForm={setAddonsForm}
                  withImages={withImages}
                  setWithImages={setWithImages}
                />
              </div>
              {error && <p className=' text-red-600'>{error}</p>}

              <div className='mt-3'>
                <b className='text-md'>{t('addons.label')}</b>
                {addonsForm?.items?.map((x: any, i: any) => {
                  return (
                    <div key={i}>
                      <AddonGroupElement
                        i={i}
                        x={x}
                        handleInputChange={handleInputChange}
                        showImages={showImages}
                        addonsForm={addonsForm}
                        toggleOpenImageGalery={toggleOpenImageGalery}
                        handleRemoveClick={handleRemoveClick}
                        openImageGalery={openImageGalery}
                        setAddonsForm={setAddonsForm}
                        setFilteredImages={setFilteredImages}
                        addonsImages={addonsImages}
                        imagesForDelete={imagesForDelete}
                        setImagesForDelete={setImagesForDelete}
                        uploadedImages={uploadedImages}
                        setUploadedImages={setUploadedImages}
                        showNettoPrice={auth.showNetto}
                        isInConfigurator={product === undefined}
                      />
                    </div>
                  );
                })}
              </div>

              <div className='flex justify-center'>
                <button
                  type='button'
                  className='relative_add_button w-auto h-7 mt-3'
                  onClick={() => {
                    handleAddAddons();
                  }}
                >
                  + {t('add.button')}
                </button>
              </div>

              <div className='flex justify-end'>
                <AllowMultipleSwitch
                  addonsForm={addonsForm}
                  setAddonsForm={setAddonsForm}
                  allowMultiple={allowMultiple}
                  setAllowMultiple={setAllowMultiple}
                />
              </div>

              {/* <ModalButtons toEdit={addonToEdit} product={product} deletee={handleDelete} cancel={cancel} save={handleSave} addonsForm={addonsForm}/> */}

              <div className=' mt-6 flex items-center justify-between'>
                <div>
                  {addonToEdit !== -1 && addonToEdit !== undefined && (
                    <button
                      type='button'
                      className='relative_red_button w-24 h-9'
                      onClick={() => {
                        modalConfirm(
                          {
                            message: t(
                              product === undefined
                                ? 'deleteAllAddons.label'
                                : 'deleteAddons.label'
                            ),
                            title: t('delete.label'),
                          },
                          handleDelete
                        );
                      }}
                    >
                      {t('delete.button')}
                    </button>
                  )}
                </div>

                <div className='flex justify-end'>
                  <button
                    type='button'
                    className='cancel_button h-9 w-24 mr-2'
                    onClick={cancel}
                  >
                    {t('cancel.button')}
                  </button>
                  <button
                    className='relative_blue_button h-9 w-24'
                    type='submit'
                  >
                    {t('save.button')}
                  </button>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
