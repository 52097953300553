import calculateVatFromPrice from 'utils/calculateVatFromPrice';
import db from '../firebase';
import toast from 'react-hot-toast';

export const dragAddonFromConfiguratorToProduct = async (
  result: any,
  catalogue: any,
  user: any,
  showNetto: any,
  setCatalogue: any
) => {
  //IN USAGE
  try {
    if (
      catalogue?.products[result.destination.droppableId]?.addons &&
      result.source.droppableId !== result.destination.droppableId && //checks if addon already exists in product (by name)
      catalogue?.products[result.destination.droppableId]?.addons.filter(
        (value: any) =>
          value.title ===
          (Object as any).values(
            catalogue?.configurator?.addons[result.source.index]
          )[0]?.title
      ).length > 0
    ) {
      toast.error('Addon already exists in the product');
      return;
    } else {
      //performs drag and drop
      if (result.source.droppableId === 'configureAddons') {
        const productId = result.destination.droppableId;
        const productVat =
          catalogue?.products[result.destination.droppableId].vat;
        const addonGroup: any = Object.values(
          catalogue?.configurator?.addons[result.source.index]
        )[0];
        const addonId = result.draggableId;
        dragAddonFromConfiguratorToProductt(
          catalogue,
          addonGroup,
          addonId,
          setCatalogue,
          productId,
          productVat,
          user,
          showNetto
        );
      }
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.destination.droppableId.includes('addonsOrder')
      ) {
        dragToOrderAddonChips(result, catalogue, setCatalogue, user);
      }
    }
  } catch (e) {
    toast.dismiss();
    toast.error('Addons not updated');
  }
};

const dragAddonFromConfiguratorToProductt = async (
  catalogue: any,
  addonGroup: any,
  addonId: any,
  setCatalogue: any,
  productId: any,
  productVat: any,
  user: any,
  showNetto: any
) => {
  //IN USAGE
  try {
    const addonsWithVat = addonGroup.items.map((item: any) => {
      const calcResult = calculateVatFromPrice(
        item.price,
        showNetto,
        productVat
      );
      return { ...item, ...calcResult };
    });
    const loading = toast.loading('Loading...');
    addonGroup.refId = addonId;
    setCatalogue({
      ...catalogue,
      products: {
        ...catalogue.products,
        [productId]: {
          ...catalogue.products[productId],
          addons: [
            ...(catalogue?.products[productId]?.addons || []),
            {
              ...addonGroup,
              items: addonsWithVat,
              withImages: addonGroup.withImages ? addonGroup.withImages : false,
              allowMultiple: addonGroup.allowMultiple
                ? addonGroup.allowMultiple
                : false,
            },
          ],
        },
      },
    });

    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('products')
      .doc(productId)
      .update({
        addons: [
          ...(catalogue?.products[productId]?.addons || []),
          {
            ...addonGroup,
            items: addonsWithVat,
            withImages: addonGroup.withImages ? addonGroup.withImages : false,
            allowMultiple: addonGroup.allowMultiple
              ? addonGroup.allowMultiple
              : false,
          },
        ],
      });
    toast.success('Addon added to product', { id: loading });
  } catch (e) {
    toast.dismiss();
    toast.error('Addons not added to product');
  }
};

export const dragToOrderAddonChips = async (
  result: any,
  catalogue: any,
  setCatalogue: any,
  user: any
) => {
  try {
    const loading = toast.loading('Loading..');
    const productId = result.destination.droppableId.substring(
      result.destination.droppableId.indexOf('-') + 1
    );
    const [reorderedItem] = catalogue.products[productId].addons.splice(
      result.source.index,
      1
    );
    catalogue.products[productId].addons.splice(
      result.destination.index,
      0,
      reorderedItem
    );

    setCatalogue({ ...catalogue });
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('products')
      .doc(productId)
      .update({
        addons: catalogue.products[productId].addons,
      });
    toast.success('Addons reordered', { id: loading });
    return;
  } catch (e) {
    toast.dismiss();
    toast.error('Addons order not updated');
  }
};

export const dragSizeFromConfiguratorToProduct = async (
  result: any,
  setCatalogue: any,
  catalogue: any,
  user: any,
  showNetto: any
) => {
  try {
    const loading = toast.loading('Loading...');
    const { destination, source, draggableId } = result;
    const productId = destination.droppableId.substring(
      destination.droppableId.indexOf('-') + 1
    );
    if (destination.droppableId === 'sizes') {
      return;
    }
    const productVat = catalogue?.products[result.destination.droppableId].vat;

    const sizeItemsWithVat = (Object as any)
      .values(catalogue.configurator.sizes[source.index])[0]
      .items.map((size: any) => {
        const calcResult = calculateVatFromPrice(
          size.price,
          showNetto,
          productVat
        );
        // TODOTESSA - this is not working...., next sizes and addons configurator
        return { ...size, ...calcResult };
      });
    setCatalogue({
      ...catalogue,
      products: {
        ...catalogue.products,
        [productId]: {
          ...catalogue.products[productId],
          sizes: {
            items: sizeItemsWithVat,
            refId: draggableId,
            defaultSize: (Object as any).values(
              catalogue.configurator.sizes[source.index]
            )[0].defaultSize,
          },
        },
      },
    });
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('products')
      .doc(productId)
      .update({
        sizes: {
          items: sizeItemsWithVat,
          refId: draggableId,
          defaultSize: (Object as any).values(
            catalogue.configurator.sizes[source.index]
          )[0].defaultSize,
        },
      });

    toast.success('Sizes added to product', { id: loading });
    return;
  } catch (e) {
    toast.dismiss();
    toast.error('Sizes not added');
  }
};
