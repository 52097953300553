import { useTranslation } from 'react-i18next';

export default function OptionalOrMandatory({
  addonsForm,
  setAddonsForm,
  mandatory,
  setMandatory,
}: any) {
  const { t } = useTranslation(['catalogue']);
  console.log('addonsForm', addonsForm);
  console.log('mandatory', mandatory);

  return (
    <div className='bg-gray-100 rounded-md flex justify-center items-center'>
      <button
        type='button'
        className={
          'mouse-pointer w-28 h-9' +
          (mandatory
            ? ' text-gray-400'
            : '  bg-white m-0.5  border-gray-400 rounded-md')
        }
        onClick={() => {
          setMandatory(false);
          setAddonsForm({ ...addonsForm, mandatory: false, minimum: 0 });
        }}
      >
        {t('optional.label')}
      </button>
      <button
        type='button'
        onClick={() => {
          setMandatory(true);
          setAddonsForm({ ...addonsForm, mandatory: true, minimum: 1 });
        }}
        className={
          'mouse-pointer w-28 h-9' +
          (mandatory
            ? ' bg-white rounded-md m-0.5 border-gray-400'
            : '  text-gray-400')
        }
      >
        {t('mandatory.label')}
      </button>
    </div>
  );
}
