import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DotsIcon } from '../../../assets/dots.svg';
import EditIcon from '../../EditIcon';

export default function AddonGroupCard({
  cardElement,
  index,
  setAddonToEdit,
  setOpenAddAddonsModal,
  addonId,
}: any) {
  return (
    <Draggable
      key={cardElement.title + index}
      draggableId={addonId}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className='border rounded-xl my-2 p-2 bg-white z-40'
          key={index}
        >
          <div className='flex w-full'>
            <div className='flex justify-start items-center mr-2'>
              <DotsIcon className='w-6 h-6' />
            </div>
            <b className='w-4/6 flex justify-start items-center text-lg mr-1 line-clamp-2'>
              {cardElement.title.length > 20
                ? cardElement.title.substring(0, 13) + '...'
                : cardElement.title}
            </b>
            <div
              className='w-2/6 ml-5 flex relative justify-end items-center'
              onClick={() => {
                setAddonToEdit(index);
                setOpenAddAddonsModal(true);
              }}
            >
              <EditIcon />
            </div>
          </div>
          <div className={'flex flex-wrap p-1'}>
            {cardElement.items.map((singleTopping: any, index: number) => {
              return (
                <div
                  key={index}
                  className=' text-white   mt-2 rounded-button bg-green_chip text-sm flex items-center
                                px-4 h-8 mr-2'
                >
                  {singleTopping.addon.length > 13
                    ? singleTopping.addon.substring(0, 10) + '...'
                    : singleTopping.addon}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Draggable>
  );
}
