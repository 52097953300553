import axios from 'axios';
import { signUpForOnboarding } from 'firebase-logic/onboarding';
import React, { useEffect, useState } from 'react';
import { SingleStep } from 'typings/OnboardingModuleType';
import {
  defaultColor,
  defaultCoverLink,
  defaultFaviconLink,
  defaultLogoLink,
} from 'utils/defaultDataForNewCompany';
import {
  onboardingInitialData,
  useOnboardingState,
} from '../../global-states/useOnboardingState';
import verifyMailImg from '../../assets/verify_mail.png';
import { NextButton } from './NextButton';
import useAuthState, { initFirebaseAuth } from 'global-states/useAuthState';
import firebase from 'firebase';
import toast from 'react-hot-toast';
import { auth } from 'firebase-logic/firebase';
import { useTranslation } from 'react-i18next';
import { SubscriptionAPI } from 'api/subscription';
import {
  countryCodesWithClockFormat12,
  countryCodesWithIncludedTax,
} from '../../constants/countryCodes';
import { REACT_APP_GOOGLE_MAPS_TIME_ZONE_API_KEY } from '../../config';

type Props = {
  handleNextClick?: any;
  currentStep?: SingleStep;
};

const onboardingStateSelector = (state: any) => [
  state.onboarding,
  state.setOnboardingState,
];

const getTimeZoneId = async (latitude: number, longitude: number) => {
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.floor(
    Date.now() / 1000
  )}&key=${REACT_APP_GOOGLE_MAPS_TIME_ZONE_API_KEY}`;
  try {
    const response = await axios.get(url);
    return response.data.timeZoneId; // Google API returns timeZoneId
  } catch (error) {
    console.error('Error fetching time zone:', error);
    throw error;
  }
};

export const StoreCreationStep: React.FC<Props> = ({ currentStep }) => {
  const { t } = useTranslation(['onboarding']);
  const [onboarding, setOnboardingState] = useOnboardingState(
    onboardingStateSelector
  );
  const { user, loading }: { user: firebase.User; loading: Boolean } =
    useAuthState() as any;
  const [isCreated, setIsCreated] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [loadingText, setLoadingText] = useState(
    'wearepreparingyouronlinestorestaythere.text'
  );

  useEffect(() => {
    if (onboarding.allStepsCompleted && !isFailed && !isCreated) {
      startCompanyCreation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding.allStepsCompleted, isFailed, isCreated]);

  const startCompanyCreation = async () => {
    const { userUid, msg } = await signUpForOnboarding(
      onboarding.storeAuthStep.email,
      onboarding.storeAuthStep.password
    );
    // TODO: remove password and email from zustand
    if (userUid) {
      const isSuccess = await createCompany(userUid);
      if (isSuccess) {
        setLoadingText('tomakeyourdroovaccountmoresecure.text');
        setIsCreated(true);
      }
    } else {
      toast.error(t('auth_failed.toast'));
      if (msg === 'auth/email-already-in-use') {
        setIsFailed(true);
        setLoadingText('auth_emailInUse.label');
      } else {
        setIsFailed(true);
        setLoadingText('auth_failed.label');
      }
    }
  };

  // // <!--Insert trackdesk tracker -->
  // useEffect(() => {
  //   if (isCreated) {
  //     const script1 = document.createElement("script");
  //     script1.src = "//cdn.trackdesk.com/tracking.js";
  //     script1.async = true;

  //     const script2 = document.createElement("script");

  //     script2.innerHTML = `(function(t,d,k){(t[k]=t[k]||[]).push(d);t[d]=t[d]||t[k].f||function(){(t[d].q=t[d].q||[]).push(arguments)}})(window,"trackdesk","TrackdeskObject");
  //     trackdesk('droov', 'externalCid', {
  //       externalCid: ${onboarding.storeAuthStep.email},
  //       revenueOriginId: '2d0dba28-5ed8-484f-a3d0-5f31dfb2f0e0'
  //     });`;

  //     document.body.appendChild(script1);
  //     document.body.appendChild(script2);
  //   }

  // }, [isCreated]);

  const createCompany = async (uid: string) => {
    const timeZoneID = await getTimeZoneId(
      onboarding.storeLocationStep.lattitude,
      onboarding.storeLocationStep.longitude
    );

    const referral = (window as any).tolt_referral || '';

    console.log('Referral is here: ', referral);

    const companyData = {
      name: onboarding.storeInfoStep.storeName,
      urlStore: `https://${onboarding.storeInfoStep.storeUrl}.droov.store`,
      prefix: onboarding.storeInfoStep.storeUrl,
      delivery: true,
      pickup: true,
      localizationSetting: {
        countryCode: onboarding.storeLocationStep.countryCode,
        language: onboarding.storeLocationStep.language,
        currency: onboarding.storeLocationStep.currency,
        utcOffsetMinutes: onboarding.storeLocationStep.utcOffsetMinutes,
        // if company in US/CA then showNetto is false
        showNetto: countryCodesWithIncludedTax.includes(
          onboarding.storeLocationStep.countryCode
        ),
        clockFormat12: countryCodesWithClockFormat12.includes(
          onboarding.storeLocationStep.countryCode
        ),
        timeZoneID: timeZoneID,
      },
      locationSetting: {
        additionalInformation: '',
        city: onboarding.storeLocationStep.city,
        postCode: onboarding.storeLocationStep.postcode,
        streetAndHouseNumber: onboarding.storeLocationStep.streetAndHouse,
        country: onboarding.storeLocationStep.country,
        geoCode: {
          lat: onboarding.storeLocationStep.lattitude,
          lng: onboarding.storeLocationStep.longitude,
        },
      },
      paymentSettings: {
        cardPayment: false,
        cashPayment: true,
        payPalPayment: false,
      },
      storeSettings: {
        customerNotifications: false,
        basketLimit: {
          enabled: false,
          basketLimit: 150,
        },
        catering: {
          enabled: true,
        },
        timeslotLimit: {
          enabled: false,
          maxItems: 5,
          avgItemPrice: 10,
        },
        defaultTip: 0,
        defaultTipping: true,
        groupOrder: false,
        groupOrderDiscount: 0,
        groupOrderMinValue: 0,
      },
      contactSetting: {
        customerName: onboarding.storeAuthStep.customerName,
        contactEmail: onboarding.storeAuthStep.email,
        storeDescription: '',
        storeName: onboarding.storeInfoStep.storeName,
        telephone: onboarding.storeInfoStep.storePhoneNumber,
      },
      // default billing setting
      billingSetting: {
        address: {
          city: onboarding.storeLocationStep.city,
          country: onboarding.storeLocationStep.country,
          line1: onboarding.storeLocationStep.streetAndHouse,
          postal_code: onboarding.storeLocationStep.postcode,
        },
        customer_name: onboarding.storeAuthStep.customerName,
        email: onboarding.storeAuthStep.email,
        name: onboarding.storeInfoStep.storeName,
        phone: onboarding.storeInfoStep.storePhoneNumber,
      },
      storeStatus: true,
      deliveryAreas: [],
      urlCoverImage: defaultCoverLink,
      urlFavicon: defaultFaviconLink,
      urlLogo: defaultLogoLink,
      color: defaultColor,
    };

    console.log('Company Data: ', companyData);

    const res = await SubscriptionAPI.createNewCompany(
      uid,
      companyData,
      referral
    );

    return res.data?.success;
  };

  const saveCurrentStepData = async () => {
    const userDetails = await auth.signInWithEmailAndPassword(
      onboarding.storeAuthStep.email,
      onboarding.storeAuthStep.password
    );
    await initFirebaseAuth();

    if (!userDetails.user?.emailVerified) {
      toast(t('email_not_verified.label'), {
        icon: '⚠️',
        position: 'top-center',
      });
    } else {
      toast.success(t('sing_in_success.label'));
      setOnboardingState(onboardingInitialData);
    }
  };

  return (
    <div className='flex flex-col items-center'>
      {!isCreated && (
        <>
          {!isFailed && (
            <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mt-12 mb-12' />
          )}
          <p className='text-lg text-center'>{t(loadingText)}</p>
        </>
      )}
      {isCreated && (
        <>
          <img
            className='w-56 mx-3 h-auto opacity-50'
            src={verifyMailImg}
            alt='verify mail'
          />
          <p className='text-lg text-center mb-12 sm:mx-3'>{t(loadingText)}</p>
          <NextButton
            disabled={false}
            buttonText={
              currentStep?.stepNumber === 3
                ? t('login.button')
                : t('next.button')
            }
            goNextHandler={saveCurrentStepData}
          />
        </>
      )}
    </div>
  );
};
