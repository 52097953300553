import axios from 'axios';
import * as firebase from 'firebase-logic/firebase';

const isTest = false; //TODO: to change for live
const apiUrl = isTest
  ? 'https://europe-west3-droov-group-1.cloudfunctions.net/testBilling'
  : 'https://europe-west3-droov-group-1.cloudfunctions.net/billing';

export const SubscriptionAPI = {
  createNewCompany: (uid: string, companyData: any, referral: string) => {
    return axios.post(apiUrl + '/create-new-company', {
      userUid: uid,
      companyData: companyData,
      referral: referral,
    });
  },

  requestBillingPortal: () => {
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(apiUrl + '/get-billing-portal', {
        method: 'GET',
        headers: {
          'Content-Type': 'applicaton/json',
          Authorization: `Bearer ${token}`,
        },
      });
    });
  },
  getSubscription: () => {
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(apiUrl + '/get-subscription', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });
  },

  getCustomer: () => {
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(apiUrl + '/get-customer', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });
  },
  updateCustomer: (updatedBillingInformation: {
    name: string;
    email: string;
    address: {
      line1: string;
      line2: string;
      postal_code: string;
      city: string;
      country: string;
    };
    phone: string;
    vat: string;
    customer_name: string;
  }) => {
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(apiUrl + '/update-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'applicaton/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedBillingInformation),
      });
    });
  },
};
