import { Transition } from '@headlessui/react';
import { useOnboardingState } from 'global-states/useOnboardingState';
import React, { useEffect, useState } from 'react';
import { SingleStep } from 'typings/OnboardingModuleType';
import CustomInputField from 'ui-components/CustomInputField';
import { NextButton } from './NextButton';
import CustomCheckBox from 'ui-components/EcoFriendlyCheck';
import CustomLoadingScreen from 'ui-components/CustomLoadingScreen';
import { useTranslation } from 'react-i18next';

interface StoreAuthDataObject {
  customerName: string;
  email: string;
  password: string;
  repeatedPassword: string;
  referral: string;
}

type Props = {
  handleNextClick?: any;
  currentStep?: SingleStep;
};

const onboardingStateSelector = (state: any) => [
  state.onboarding,
  state.setOnboardingState,
];

/*const upperCaseChars = /[A-Z]/;*/
const passwordMinLength = 6;
export const StoreAuthStep: React.FC<Props> = ({
  handleNextClick,
  currentStep,
}) => {
  const { t } = useTranslation(['onboarding']);

  const [onboarding, setOnboardingState] = useOnboardingState(
    onboardingStateSelector
  );

  const [storeAuthData, setStoreAuthData] = useState<StoreAuthDataObject>({
    customerName: '',
    email: '',
    password: '',
    repeatedPassword: '',
    referral: '',
  });
  const [matchPasswords, setMatchPasswords] = useState(false);
  const [hasValidPassword, setHasValidPassword] = useState(true);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordValidationMsgs, setPasswordValidationMsgs] = useState<
    string[]
  >([]);
  const [loadingText, setLoadingText] = useState(
    t('creating_company_wait.label')
  );

  useEffect(() => {
    if (onboarding?.storeAuthStep?.completed) {
      setStoreAuthData({
        ...onboarding.storeAuthStep,
      });
    }
  }, [onboarding.storeAuthStep]);

  useEffect(() => {
    if (matchPasswords) {
      checkPassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeAuthData.password, storeAuthData.repeatedPassword]);

  useEffect(() => {
    if (storeAuthData.repeatedPassword.length > 0) {
      setMatchPasswords(true);
    } else {
      setMatchPasswords(false);
    }
  }, [storeAuthData.repeatedPassword]);

  const checkPassword = () => {
    if (storeAuthData.password === storeAuthData.repeatedPassword) {
      setHasValidPassword(true);
    } else {
      setHasValidPassword(false);
    }
  };

  const saveCurrentStepData = async () => {
    // if window.tolt_referral is not null, then set it to the referral field

    let temp = JSON.parse(JSON.stringify(onboarding));
    temp.allStepsCompleted = true;
    temp.storeAuthStep = {
      completed: true,
      customerName: storeAuthData.customerName,
      email: storeAuthData.email,
      password: storeAuthData.password,
      repeatedPassword: storeAuthData.repeatedPassword,
    };
    setOnboardingState(temp);
    handleNextClick(temp);
  };

  const checkPasswordValidationRules = () => {
    const tempMsgs: string[] = [];
    if (storeAuthData.password.length < passwordMinLength) {
      tempMsgs.push(
        t('password_min_length.label', { passwordMinLength: passwordMinLength })
      );
    }
    /*    if (!upperCaseChars.test(storeAuthData.password)) {
      tempMsgs.push(t('uppercase_min.label'));
    }*/
    if (tempMsgs.length > 0) {
      setHasValidPassword(false);
      setPasswordValidationMsgs(tempMsgs);
    } else {
      setHasValidPassword(true);
      setPasswordValidationMsgs([]);
    }
  };

  return (
    <Transition
      appear={true}
      show={true}
      as='div'
      enter='transform transition ease-in-out duration-1000'
      enterFrom='-translate-x-4 opacity-0'
      enterTo='-translate-x-0'
    >
      <div
        className={`mx-10 md:w-3/5 md:mx-auto flex flex-col items-center ${
          loading ? 'opacity-25' : ''
        }`}
      >
        <div className='z-10 w-full mb-6'>
          <label className='droov_label_text'>{t('yourname.label')}</label>
          <CustomInputField
            value={storeAuthData.customerName}
            onChange={(event) =>
              setStoreAuthData({
                ...storeAuthData,
                customerName: event.target.value,
              })
            }
            type='text'
            id='customerName'
            required={true}
          />
        </div>
        <div className='z-10 w-full mb-6'>
          <label className='droov_label_text'>{t('email.label')}</label>
          <CustomInputField
            value={storeAuthData.email}
            onChange={(event) =>
              setStoreAuthData({
                ...storeAuthData,
                email: event.target.value,
              })
            }
            type='text'
            id='storeEmail'
            required={true}
          />
        </div>

        <div className='z-10 w-full mb-6'>
          <label className='droov_label_text'>{t('password.label')}</label>
          <CustomInputField
            value={storeAuthData.password}
            onChange={(event) =>
              setStoreAuthData({
                ...storeAuthData,
                password: event.target.value,
              })
            }
            type='password'
            id='storePassword'
            required={true}
            onBlur={checkPasswordValidationRules}
          />
          {passwordValidationMsgs.length > 0 && (
            <ul>
              {passwordValidationMsgs.map((msg) => (
                <li className={`text-xs text-red-600 mt-1`}>{msg}</li>
              ))}
            </ul>
          )}
        </div>

        <div className='z-10 w-full mb-6 flex flex-col'>
          <div className='flex justify-center mt-3'>
            <label className='select-none inline-flex items-center mt-3'>
              <input
                checked={acceptedTerms}
                type='checkbox'
                className='form-checkbox cursor-pointer h-5 w-5 focus:ring-0 rounded-md text-droov'
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
              <span className='ml-2'>{t('i_agree_to.label')}</span>
              <span
                onClick={() => {
                  window.open('https://droov.io/policy', '_blank') ||
                    window.location.replace('https://droov.io/policy');
                }}
                className='cursor-pointer ml-2 underline'
              >
                {t('terms_and_conditions.label')}
              </span>
            </label>
          </div>
        </div>

        <div className='w-full flex flex-col items-center mt-5'>
          <NextButton
            disabled={
              !(
                storeAuthData.email.length > 0 &&
                storeAuthData.password.length > 0 &&
                hasValidPassword &&
                acceptedTerms &&
                !loading &&
                passwordValidationMsgs.length === 0
              )
            }
            buttonText={
              currentStep?.stepNumber === 2
                ? t('start.button')
                : t('next.button')
            }
            goNextHandler={saveCurrentStepData}
          />
        </div>
      </div>
      {loading && (
        <div>
          <CustomLoadingScreen />
          <div className='flex justify-center'>
            <p className='text-xl text-center'>{loadingText}</p>
          </div>
        </div>
      )}
    </Transition>
  );
};
