import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { ReactComponent as XIcon } from '../../../assets/xIcon.svg';

import AddSizesModal from '../product-components/AddSizesModal';
import ConfigureAddonsList from './ConfigureAddonsList';
import ConfigureSizesList from './ConfigureSizesList';
import AddAddonsModal from '../product-components/AddAddonsModal';
import ExpandArrow from '../../ExpandArrow';
import { useTranslation } from 'react-i18next';

export default function Configurator({
  openConfigurator,
  toggleSideBar,
  addToConfigurator,
  catalogue,
  updateConfigurator,
  addonsImages,
  addAddons,
  updateAddons,
  deleteAddons,
  setSizes,
  deleteSizes,
}: any) {
  const [addonToEdit, setAddonToEdit] = useState(-1);
  const [sizesToEdit, setSizesToEdit] = useState(-1);

  const [openAddSizesModal, setOpenAddSizesModal] = useState(false);
  const [openAddAddonsModal, setOpenAddAddonsModal] = useState(false);

  const [visibleSizes, setVisibleSizes] = useState(true);
  const [visibleAddons, setVisibleAddons] = useState(true);
  // const [defaultSize,setDefaultSize] = useState();
  const { t } = useTranslation(['catalogue']);

  return (
    <Transition
      as={Fragment}
      show={openConfigurator}
      enter='transition-opacity duration-500'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-500'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='absolute max-h-screen top-8 bottom-0 right-0 ml-5 p-5 w-72 xl:w-96 bg-white overflow-auto'>
        <div className='flex justify-between  mb-3'>
          <b className='text-lg'>{t('configurator.label')}</b>
          <XIcon
            onClick={toggleSideBar}
            className='flex w-6 items-center justify-center cursor-pointer fill-current text-gray-400 hover:text-gray-800 duration duration-300'
          />
        </div>

        <div
          className='flex justify-between mb-2 cursor-pointer group'
          onClick={() => setVisibleSizes(!visibleSizes)}
        >
          <h1 className='text-lg font-normal text-gray-500 group-hover:text-gray-900 duration-200'>
            {t('sizes.label')}
          </h1>
          <ExpandArrow
            styles={' w-3 flex items-center justify-center'}
            expanded={visibleSizes}
          />
        </div>

        <Transition
          appear={visibleSizes}
          show={visibleSizes}
          as='div'
          enter='transform transition ease-in-out duration-1000'
          enterFrom='-translate-y-4 opacity-0'
          enterTo='-translate-y-0'
        >
          {visibleSizes && (
            <ConfigureSizesList
              state={catalogue?.configurator}
              toggleSideBar={toggleSideBar}
              setOpenAddSizesModal={setOpenAddSizesModal}
              setSizesToEdit={setSizesToEdit}
              visibleSizes={visibleSizes}
            />
          )}
        </Transition>
        <AddSizesModal
          configuratorState={catalogue?.configurator}
          setSizes={setSizes}
          deleteSizes={deleteSizes}
          sizesToEdit={sizesToEdit}
          addToConfigurator={addToConfigurator}
          closeAddSizesModal={setOpenAddSizesModal}
          openAddSizesModal={openAddSizesModal}
          setSizesToEdit={setSizesToEdit}
          updateConfigurator={updateConfigurator}
        />

        <hr className='my-6' />

        <div
          className='flex justify-between cursor-pointer group'
          onClick={() => setVisibleAddons(!visibleAddons)}
        >
          <h1 className='text-lg font-normal text-gray-500 group-hover:text-gray-900 mb-2 duration-300'>
            {t('addons.label')}
          </h1>
          <ExpandArrow
            styles={'w-3 flex items-center justify-center'}
            expanded={visibleAddons}
          />
        </div>
        <Transition
          appear={visibleAddons}
          show={visibleAddons}
          as='div'
          enter='transform transition ease-in-out duration-1000'
          enterFrom='-translate-y-4 opacity-0'
          enterTo='-translate-y-0'
        >
          {visibleAddons && (
            <ConfigureAddonsList
              state={catalogue?.configurator}
              setAddonToEdit={setAddonToEdit}
              setOpenAddAddonsModal={setOpenAddAddonsModal}
            />
          )}
        </Transition>

        <AddAddonsModal
          addToConfigurator={addToConfigurator}
          configuratorState={catalogue?.configurator}
          addAddons={addAddons}
          updateAddons={updateAddons}
          deleteSizes={deleteSizes}
          addonsImages={addonsImages}
          deleteAddons={deleteAddons}
          setOpenAddAddonsModal={setOpenAddAddonsModal}
          openAddAddonsModal={openAddAddonsModal}
          addonToEdit={addonToEdit}
          setAddonToEdit={setAddonToEdit}
          updateConfigurator={updateConfigurator}
        />
      </div>
    </Transition>
  );
}
