import calculateVatFromPrice from 'utils/calculateVatFromPrice';
import db from './../firebase';
import toast from 'react-hot-toast';

export const createAddons = async (
  addonGroup: any,
  user: any,
  setCatalogue: any,
  catalogue: any,
  addonId: any,
  productId?: any,
  fetchCatalogue?: any,
  addonToEdit?: any,
  showNetto?: any,
  showToast = true,
  bulk?: any,
  temporaryCatalogue?: any,
  createAndAdd?: any
) => {
  // fix undefined fields
  addonGroup = {
    ...addonGroup,
    allowMultiple: addonGroup?.allowMultiple ?? false,
  };
  //IN USAGE
  if (addonToEdit === undefined) {
    //if creating addon
    try {
      let loading;
      // if ((!bulk.isLast || bulk?.multiple)) {
      //   loading = toast.loading('Loading...');
      // }

      let refId;
      //if at configurator
      if (productId === undefined || createAndAdd) {
        const x = await db
          .collection('companies')
          .doc(user)
          .collection('catalogue')
          .doc('catalogue')
          .collection('configuratorAddons')
          .add(addonGroup);
        setCatalogue({
          ...catalogue,
          configurator: {
            ...catalogue.configurator,
            addons: [...catalogue.configurator.addons, { [x.id]: addonGroup }],
          },
        });
        refId = x.id;
      }
      //if adding for a specific product
      if (productId !== undefined) {
        if (bulk?.multiple) {
          temporaryCatalogue.push(addonGroup);
        }
        if (createAndAdd) {
          temporaryCatalogue.push({ ...addonGroup, refId: refId });
        }
        if (bulk?.multiple && bulk?.isLast) {
          setCatalogue({
            ...catalogue,
            products: {
              ...catalogue.products,
              [productId]: {
                ...catalogue.products[productId],
                addons: [
                  ...(catalogue.products[productId]?.addons || []),
                  ...temporaryCatalogue,
                ],
              },
            },
          });
        } else if (!bulk || !bulk?.multiple) {
          setCatalogue((catalogue: any) => ({
            ...catalogue,
            products: {
              ...catalogue.products,
              [productId]: {
                ...catalogue.products[productId],
                addons: [
                  ...(catalogue.products[productId]?.addons || []),
                  ...temporaryCatalogue,
                ],
              },
            },
          }));
        }
        // console.log(catalogue.products[productId]?.addons)
        await db
          .collection('companies')
          .doc(user)
          .collection('catalogue')
          .doc('catalogue')
          .collection('products')
          .doc(productId)
          .update({
            addons: [
              ...(catalogue?.products[productId]?.addons || []),
              ...temporaryCatalogue,
            ],
          });
      }

      if (showToast) {
        toast.success('Addon created', { id: loading });
      }

      return true;
    } catch (error) {
      if (showToast) {
        toast.dismiss();
        toast.error('Addons not created');
      }
      console.error(error);
      return false;
    }
  } else {
    //if updating addon

    updateAddons(
      user,
      addonId,
      addonGroup,
      productId,
      fetchCatalogue,
      setCatalogue,
      catalogue,
      addonToEdit,
      showNetto
    );
  }
};

export const deleteAddonss = async (
  addonId: any,
  user: any,
  setCatalogue: any,
  catalogue: any,
  productId?: any,
  showToast = true,
  addonToEdit?: any,
  fetchCatalogue?: any
) => {
  try {
    if (productId === undefined) {
      //if at configurator
      const loading = toast.loading('Loading...');
      const newAddons = catalogue.configurator.addons;
      newAddons.splice(addonToEdit, 1);
      setCatalogue({
        ...catalogue,
        configurator: {
          ...catalogue.configurator,
          addons: newAddons,
        },
      });
      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('configuratorAddons')
        .doc(addonId)
        .delete();

      //if addon is deleted from configurator, it deletes that addon from all products
      const newCatalogue = catalogue;
      Object.entries(newCatalogue?.products).forEach((product: any) => {
        product[1]?.addons?.forEach((addonItem: any, index: any) => {
          if (addonItem?.refId !== undefined && addonItem?.refId === addonId) {
            const removedAddon = catalogue.products[product[0]].addons;
            removedAddon.splice(index, 1);

            newCatalogue.products[product[0]].addons = removedAddon;
            db.collection('companies')
              .doc(user)
              .collection('catalogue')
              .doc('catalogue')
              .collection('products')
              .doc(product[0])
              .update({
                addons: removedAddon,
              });
          }
        });
      });
      setCatalogue({ ...newCatalogue });
      toast.success('Addon group deleted', { id: loading });
      return;
    } else {
      let loading;
      //at a product
      //const loading = toast.loading('Loading...');
      const newAddons = catalogue.products[productId].addons;
      newAddons.splice(addonToEdit, 1);
      setCatalogue({
        ...catalogue,
        products: {
          ...catalogue.products,
          [productId]: {
            ...catalogue.products[productId],
            addons: newAddons,
          },
        },
      });
      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('products')
        .doc(productId)
        .update({
          addons: newAddons,
        });
      if (showToast) {
        toast.success('Addon deleted', { id: loading });
      }
    }
  } catch (error) {
    toast.dismiss();
    toast.error('Addon not deleted');
    return;
  }
};

const updateAddons = async (
  user: any,
  addonId: any,
  addonGroup: any,
  productId: any,
  fetchCatalogue: any,
  setCatalogue: any,
  catalogue: any,
  addonToEdit: any,
  showNetto: any
) => {
  try {
    const loading = toast.loading('Loading...');
    const newCat = catalogue;
    if (productId === undefined) {
      //update configurator
      updateConfigurator(
        newCat,
        addonToEdit,
        addonId,
        addonGroup,
        setCatalogue,
        user,
        catalogue,
        showNetto
      );
    } else {
      //update single addon chip
      if (addonGroup.refId) delete addonGroup.refId;
      newCat.products[productId].addons[addonToEdit] = addonGroup;
      setCatalogue({ ...newCat });
      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('products')
        .doc(productId)
        .set(
          {
            addons: newCat.products[productId].addons,
          },
          { merge: true }
        );
    }
    toast.success('Addon updated', { id: loading });
  } catch (error) {
    toast.dismiss();
    toast.error('Addons not updated');
    return;
  }
};

const updateConfigurator = async (
  newCat: any,
  addonToEdit: any,
  addonId: any,
  addonGroup: any,
  setCatalogue: any,
  user: any,
  catalogue: any,
  showNetto: any
) => {
  console.log(addonGroup);
  newCat.configurator.addons[addonToEdit] = { [addonId]: addonGroup };
  setCatalogue({ ...newCat });
  await db
    .collection('companies')
    .doc(user)
    .collection('catalogue')
    .doc('catalogue')
    .collection('configuratorAddons')
    .doc(addonId)
    .set(
      {
        general: true,
        items: addonGroup.items,
        maximum: addonGroup.maximum,
        minimum: addonGroup.minimum,
        title: addonGroup.title,
        mandatory: addonGroup.mandatory,
        withImages: addonGroup.withImages,
        allowMultiple: addonGroup.allowMultiple || false,
      },
      { merge: true }
    );

  //if addon is updated from configurator, it updates that addon from all products
  // additionally, based on products vat netto/brutto price and vat amount is calculated
  const newCatalogue = JSON.parse(JSON.stringify(catalogue));
  const newAddons = JSON.parse(JSON.stringify(addonGroup));
  Object.entries(newCatalogue?.products).forEach((product: any) => {
    product[1]?.addons?.forEach((addonItem: any, index: any) => {
      if (addonItem.refId !== undefined && addonItem.refId === addonId) {
        newAddons.refId = addonId;
        const addonsWithVat = addonGroup.items.map((item: any) => {
          const calcResult = calculateVatFromPrice(
            item.price,
            showNetto,
            newCatalogue.products[product[0]].vat
          );
          return { ...item, ...calcResult };
        });
        newAddons.items = addonsWithVat;
        newCatalogue.products[product[0]].addons[index] = newAddons;

        setCatalogue({ ...newCatalogue });
        db.collection('companies')
          .doc(user)
          .collection('catalogue')
          .doc('catalogue')
          .collection('products')
          .doc(product[0])
          .set(
            {
              addons: newCatalogue.products[product[0]].addons,
            },
            { merge: true }
          );
      }
    });
  });
};
