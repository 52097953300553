// @ts-ignore
import ordersAnimation from "../assets/video/orders-animation.mp4";
import droovLogoBlack1 from "../assets/droov_logo_black.png";

export const LoginScreenVideo = () => {
  return (
    <div className='lg:w-2/3 relative'>
      {/* <!-- video here  --> */}
      <video
        id='login_video'
        autoPlay
        loop
        muted
        className='absolute top-0 w-full h-full bg-center bg-cover object-fit -z-10 xl:h-screen'
      >
        <source src={ordersAnimation} type='video/mp4'/>
      </video>
    </div>
  );
};

export const PrivacyImprints = () => {

  return (
    <>
      {/* <!-- privacy imprint texts --> */}
      <div
        className='text-droov-contact-bottom-color font-light absolute left-1/2 transform -translate-x-1/2 bottom-6 space-x-10 sm:space-x-14 flex flex-row sm:flex-row'>
        <a href='https://www.droov.io' className=''>
          Website
        </a>
        <a
          href='https://droov.io/policy'
          id='login_privacy'
          className='whitespace-nowrap'
        >
          Privacy policy
        </a>
        <a href='https://droov.io/imprint' id='login_imprint' className=''>
          Imprint
        </a>
      </div>
    </>
  );
};

export const DroovLogo = () => {
  return (
    <>
      {/* <!-- DROOV LOGO left part --> */}
      <div>
        <div
          className='text-droov-contact-bottom-color absolute left-1/4 transform -translate-x-1/2 top-6 space-x-4'>
          <a href='https://droov.io'>
            {' '}
            <img
              alt='Last-mile delivery platform droov icon '
              className='animate-img_appear_motion_leftToRight py-2 h-11'
              src={droovLogoBlack1}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export const CreateAccountButton = () => {
  return (
    <>
      {/* <!-- create account text right part --> */}
      <div>
        <div
          className='text-droov-contact-bottom-color absolute left-3/4 transform -translate-x-1/2 top-10 space-x-4 flex flex-wrap'>
          <a
            id='login_header'
            href='https://app.droov.io/onboarding'
            className='whitespace-nowrap'
          >
            Create new account
          </a>
        </div>
      </div>
    </>
  );
};